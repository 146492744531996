<i18n>
{
  "ru": {
    "title": "Изменение пользователя"
  },
  "he": {
    "title": "שינוי משתמש"
  }
}
</i18n>

<template>
  <div>
    <h1 class="text-h4 mb-2">{{ $t('title') }}</h1>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="1500"
      :color="snackbar.color"
      centered
    >
      <div class="text-center" v-html="snackbar.text"></div>
    </v-snackbar>

    <v-form v-model="valid">
      <v-row class="mb-5">
        <template v-for="(item, index) in propsInfo">
          <v-col
            cols="6"
            :key="`prop-item-info-${index}`"
          >
            <template v-if="item.type === 'switch'">
              <v-switch
                v-model="item.value"
                :label="item.name"
                :disabled="item.disabled"
                color="teal"
                :false-value="item.falseValue"
                :true-value="item.trueValue"
              ></v-switch>
            </template>

            <template v-if="item.type === 'text'">
              <v-text-field
                v-model="item.value"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
                color="teal"
              ></v-text-field>
            </template>

            <template v-if="item.type === 'select'">
              <v-select
                v-model="item.value"
                :items="item.list"
                item-text="name"
                item-value="id"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
                color="teal"
              ></v-select>
            </template>
          </v-col>
        </template>
      </v-row>

      <v-row class="mb-5">
        <v-col
          cols="12"
        >
          <v-switch
            v-model="changePassword"
            :label="$t('changePassword')"
            color="teal"
          ></v-switch>
        </v-col>

        <template v-if="changePassword">
          <template v-for="(item, index) in propsPassword">
            <v-col
              cols="6"
              :key="`prop-item-password-${index}`"
            >
              <template v-if="item.type === 'password'">
                <v-text-field
                  v-model="item.value"
                  :label="item.name"
                  :disabled="item.disabled"
                  :required="item.required"
                  :rules="(item.code === 'CONFIRM_PASSWORD') ? [matchPasswords] : item.rules"
                  color="teal"
                  :type="item.type"
                ></v-text-field>
              </template>
            </v-col>
          </template>
        </template>
      </v-row>

      <v-row class="mt-5">
        <v-col
          cols="auto"
        >
          <v-btn
            color="teal"
            dark
            :loading="btnLoading"
            @click="save()"
          >{{ $t('btn.save') }}</v-btn>
        </v-col>

        <v-col
          cols="auto"
        >
          <v-btn
            color="grey lighten-5"
            to="/users/"
          >{{ $t('btn.cancel') }}</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'UserEdit',
  data: (vm) => ({
    valid: false,
    btnLoading: false,
    snackbar: {},
    changePassword: false,
    props: [
      {
        name: vm.$t('props.NAME'),
        code: 'NAME',
        type: 'text',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: '',
        group: 'info',
      },
      {
        name: vm.$t('props.EMAIL'),
        code: 'EMAIL',
        type: 'text',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: '',
        group: 'info',
      },
      {
        name: vm.$t('props.GROUP_ID'),
        code: 'GROUP_ID',
        type: 'select',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: 5,
        list: [
          {
            id: 6,
            name: vm.$t('userGroup.admin'),
          },
          {
            id: 5,
            name: vm.$t('userGroup.manager'),
          },
        ],
        group: 'info',
      },
      {
        name: vm.$t('props.UF_ACTIVE'),
        code: 'ACTIVE',
        type: 'switch',
        disabled: false,
        required: false,
        rules: [],
        value: 'Y',
        group: 'info',
        falseValue: 'N',
        trueValue: 'Y',
      },
      {
        name: vm.$t('props.PASSWORD'),
        code: 'PASSWORD',
        type: 'password',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: '',
        group: 'password',
      },
      {
        name: vm.$t('props.CONFIRM_PASSWORD'),
        code: 'CONFIRM_PASSWORD',
        type: 'password',
        disabled: false,
        required: true,
        value: '',
        group: 'password',
      },
    ],
  }),
  computed: {
    id() {
      return +this.$route.params.id;
    },
    propsInfo() {
      return this.props.filter((item) => item.group === 'info');
    },
    propsPassword() {
      return this.props.filter((item) => item.group === 'password');
    },
    propPasswordValue() {
      const find = this.props.find((item) => item.code === 'PASSWORD');

      return (find) ? find.value : '';
    },
    propConfirmPasswordValue() {
      const find = this.props.find((item) => item.code === 'CONFIRM_PASSWORD');

      return (find) ? find.value : '';
    },
    matchPasswords() {
      return this.propPasswordValue === this.propConfirmPasswordValue || this.$t('errorMatchPassword');
    },
  },
  methods: {
    getProps() {
      this.$store.dispatch('getUser', this.id).then((response) => {
        const { result } = response.data.data;

        if (result) {
          this.props = this.props.map((item) => {
            const prop = item;

            prop.value = result[prop.code];

            return prop;
          });
        }
      });
    },
    save() {
      if (this.valid) {
        this.btnLoading = true;
        const params = new FormData();
        const props = {};

        this.props.forEach((prop) => {
          if (!this.changePassword && (prop.code === 'PASSWORD' || prop.code === 'CONFIRM_PASSWORD')) return;

          props[prop.code] = prop.value;
        });

        params.append('id', this.id);
        params.append('params', JSON.stringify(props));

        this.$store.dispatch('updateUser', params).then((response) => {
          const { success } = response.data.data;

          this.btnLoading = false;
          this.snackbar = {
            show: true,
            color: (success) ? 'green darken-3' : 'red darken-3',
            text: response.data.data.message,
          };
        });
      } else {
        this.snackbar = {
          show: true,
          color: 'red darken-3',
          text: this.$t('errorEmptyProps'),
        };
      }
    },
  },
  created() {
    this.getProps();
  },
};
</script>
